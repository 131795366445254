<mat-dialog-content>
  <div class="title">
    <img *ngIf="!data.editFlg" class="label-pre-img"
      src="assets/notice/mark_gray.png" srcset="
                assets/notice/mark_gray.png    1x,
                assets/notice/mark_gray@2x.png 2x,
                assets/notice/mark_gray@3x.svg 3x
              " />
    <img *ngIf="data.editFlg" class="label-pre-img"
      src="assets/notice/mark_blue.png" srcset="
                assets/notice/mark_blue.png    1x,
                assets/notice/mark_blue@2x.png 2x,
                assets/notice/mark_blue@3x.svg 3x
              " />
    @if (data.type==Constant.multiMsgEditTypeMessage) {
      <span [class.bold-color]="!data.editFlg">
        メッセージ <span class="required" [class.font-color]="!data.editFlg">※必須（100文字以上）</span>
      </span>
      @if (data.editFlg) {
        <button class="info-button" (click)="onMessageInfo()">
          <img src="assets/talent-carte/info.svg" />
        </button>
        <a class="info-text" (click)="onMessageInfo()">URL入力時の注意</a>
      }
    }
    <span *ngIf="data.type==Constant.multiMsgEditTypeCsv" [class.bold-color]="!data.editFlg">
      タイトル <span class="required" [class.font-color]="!data.editFlg">※必須</span>
    </span>
    <span *ngIf="data.type==Constant.multiMsgEditTypeMemo">
      管理用タイトル・メモ（タレントには公開されません。）
    </span>
  </div>
  <form [formGroup]="form">
    <ng-container *ngIf="data.type==Constant.multiMsgEditTypeCsv">
      <div class="label-count" [class.length-red]="title.value.length>maxLenTitleCsv">{{ title.value.length }}/{{ maxLenTitleCsv }}</div>
      <input class="input-title" type="text" formControlName="title" #title placeholder="タレントに届くメールの件名になります。" [readonly]="!data.editFlg">
    </ng-container>
    <ng-container *ngIf="data.type==Constant.multiMsgEditTypeMemo">
      <div class="label-count" [class.length-red]="title.value.length>maxLenTitle">{{ title.value.length }}/{{ maxLenTitle }}</div>
      <input class="input-title" type="text" formControlName="title" #title placeholder="タイトル" [readonly]="!data.editFlg">
    </ng-container>
    <div class="title" *ngIf="data.type==Constant.multiMsgEditTypeCsv">
      <img *ngIf="data.type==Constant.multiMsgEditTypeCsv && !data.editFlg"
      src="assets/notice/mark_gray.png" class="label-pre-img" srcset="
                assets/notice/mark_gray.png    1x,
                assets/notice/mark_gray@2x.png 2x,
                assets/notice/mark_gray@3x.svg 3x
              " />
      <img *ngIf="!data.type==Constant.multiMsgEditTypeCsv || data.editFlg"
        src="assets/notice/mark_blue.png" class="label-pre-img" srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                " />
      <span *ngIf="data.editFlg">
        本文 <span class="required">※必須（100文字以上）</span>
      </span>
      <span *ngIf="!data.editFlg" class="bold-color">
        本文 <span class="required font-color">※必須（100文字以上）</span>
      </span>
      @if (data.editFlg) {
        <button class="info-button" (click)="onMessageInfo()">
          <img src="assets/talent-carte/info.svg" />
        </button>
        <a class="info-text" (click)="onMessageInfo()">URL入力時の注意</a>
      }
    </div>
    <div class="label-count" [class.length-red]="text.value.length>Constant.vlMaxSendMessage">{{ text.value.length }}/{{ Constant.vlMaxSendMessage }}</div>
    <textarea class="input-textarea" formControlName="text" #text placeholder="{{placeholder}}" [readonly]="!data.editFlg"
    [class.short]="data.type==Constant.multiMsgEditTypeMemo || data.type==Constant.multiMsgEditTypeCsv"></textarea>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onOK()" class="yes-btn" [disabled]="!data.editFlg || form.invalid || isSaving">保存</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
