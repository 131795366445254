<mat-dialog-content>
  <div class="content">
    <div class="spinner center" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <div class="header" *ngIf="!showSpinner">
      <ng-container *ngIf="mode == Constant.joTypeEdit">
        <ng-container *ngIf="jobOffer.release_status == Constant.joReleaseStatusNoRelease">
          <div class="release_status default">未公開</div>
          @if (jobOffer.limited_flag == 1) {
            <img class="limited-icon" src="assets/common/lock_red.svg">
          }
        </ng-container>
        <ng-container *ngIf="jobOffer.release_status == Constant.joReleaseStatusRelease">
          <div class="release_status released">公開中</div>
          @if (jobOffer.limited_flag == 1) {
            <img class="limited-icon" src="assets/common/lock_blue.svg">
          }
        </ng-container>
        <ng-container *ngIf="jobOffer.release_status == Constant.joReleaseStatusStop">
          <div class="release_status stopped">休止中</div>
          @if (jobOffer.limited_flag == 1) {
            <img class="limited-icon" src="assets/common/lock_gray.svg">
          }
        </ng-container>
      </ng-container>
      <ng-container *ngIf="mode == Constant.joTypeAdd">
        <div class="release_status new">新規作成</div>
      </ng-container>
      <div class="description">
        求人情報は、１職種・1雇用形態ずつ作成してください。<br />
      </div>
    </div>
    <form [formGroup]="form" *ngIf="form">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x" />
          求人情報 メイン画像 <span class="normal">{{ Constant.prefixFormatPngJpg }}、{{Constant.prefixImageTriming169 }}</span>
      </div>
      <div class="image-container">
        <div *ngIf="jobImagePath == Constant.empty" class="noimage">
          <span>No Image</span>
        </div>
        <img *ngIf="jobImagePath != Constant.empty" class="jobimage" [src]="jobImagePath" />
        <div class="buttons">
          <div mat-icon-button (click)="imgFileInput.click()">
            <img src="assets/joboffer/image_upload.png" srcset="
                assets/joboffer/image_upload.png    1x,
                assets/joboffer/image_upload@2x.png 2x,
                assets/joboffer/image_upload@3x.svg 3x
              " />
            <input hidden type="file" #imgFileInput accept="image/png,image/jpeg" (change)="onChangeImage($event)" />
          </div>
          <div mat-icon-button (click)="onImageDelete()">
            <img src="assets/joboffer/image_delete.png" srcset="
                assets/joboffer/image_delete.png    1x,
                assets/joboffer/image_delete@2x.png 2x,
                assets/joboffer/image_delete@3x.svg 3x
              " />
          </div>
        </div>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          タイトル(職種名) <span class="required">※必須 </span><span class="normal">※求人を外部サービスに登録させたい場合は、職種名のみを簡潔に記載してください</span>
        </div>
        <div class="label-count" [class.length-red]="title.value.length>maxLenTitle">{{ title.value.length }}/{{ maxLenTitle }}</div>
        <input
          class="input-text"
          formControlName="title"
          #title
          placeholder="（例）スマホアプリ開発エンジニア（受託開発）"
          [maxlength]="maxLenTitle"
        />
      </div>
      <div class="flexarea">
        <div class="job-categoryarea">
          <div class="label">
            <img
              src="assets/notice/mark_blue.png"
              srcset="
                assets/notice/mark_blue.png    1x,
                assets/notice/mark_blue@2x.png 2x,
                assets/notice/mark_blue@3x.svg 3x
              "
            />
            職種カテゴリー <span class="required">※大分類は必須</span>
          </div>
          <div class="jobcategory-selectarea">
            <div class="jobcategory-large">
              <mat-form-field floatLabel="never" class="select-value job-category">
                <mat-select panelWidth=""
                  panelClass="jobofferSelect"
                  formControlName="job_category"
                  placeholder="大分類"
                  (selectionChange)="onCategorySelected()"
                >
                  <mat-option *ngFor="let jobcategory of jobCategories" [value]="jobcategory.large_id">
                    {{ jobcategory.large_item_value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="never" class="select-value job-category last-select">
                <mat-select panelWidth=""
                  panelClass="jobofferSelect"
                  formControlName="job_category_middle"
                  placeholder="中分類"
                >
                  <mat-option class="middle-option"
                    *ngFor="let jobcategorymiddle of jobCategoriesMiddle"
                    [value]="jobcategorymiddle.id"
                  >
                    {{ jobcategorymiddle.item_value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          求人PR <span class="normal">※画像にリンク設定はできません。</span>
        </div>
        <app-quill-common *ngIf="editorData" [textData]="editorData[0]" (closeDialog)="onCloseDialog()" #quillPr></app-quill-common>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          仕事内容 <span class="required">※必須 </span><span class="normal">※画像にリンク設定はできません。</span>
        </div>
        <app-quill-common *ngIf="editorData" [textData]="editorData[1]" (closeDialog)="onCloseDialog()" #quillDesc></app-quill-common>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          求めるスキル/経験 <span class="required">※必須 </span>
        </div>

        <div class="label-count" [class.length-red]="skill.value.length>maxLen400">
          {{ skill.value.length }}/{{ maxLen400 }}
        </div>
        <textarea
          class="input-textarea skill"
          formControlName="skill"
          #skill
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          求める人物像
        </div>
        <div class="label-count" [class.length-red]="person_type.value.length>maxLen400">
          {{ person_type.value.length }}/{{ maxLen400 }}
        </div>
        <textarea
          class="input-textarea skill"
          formControlName="person_type"
          #person_type
        ></textarea>
      </div>
      <div class="employtype-area">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          雇用形態 <span class="required">※必須 </span>
        </div>
        <div class="value-area">
          <mat-form-field floatLabel="never" class="select-value employment">
            <mat-select
              formControlName="employment_type"
              placeholder="選択 *必須">
              <mat-option *ngFor="let employmentType of employmentTypeMaster" [value]="employmentType.id">
                {{ employmentType.item_value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          契約期間 <span class="required">※必須 </span>
        </div>
        <div class="indent-radio">
          <mat-radio-group formControlName="contract_period" #contract_period>
            <mat-radio-button *ngFor="let item of master[Constant.joContractPeriodType]" color="primary" [value]="item.id">
              {{ item.item_value }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="form.value.contract_period != Constant.contractPeriodTypeNone">
          <div class="label-count radio-bottom" *ngIf="contract_period_detail"
          [class.length-red]="contract_period_detail.value.length>maxLen100">
            {{ contract_period_detail.value.length }}/{{ maxLen100 }}
          </div>
          <textarea
            class="input-textarea contract"
            formControlName="contract_period_detail"
            placeholder="契約期間や注意事項など"
            #contract_period_detail
          ></textarea>
          <mat-error *ngIf="!contract_period_detail.value" class="error">詳細を入力してください。</mat-error>
        </div>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          試用期間 <span class="required">※必須 </span>
        </div>
        <div class="indent-radio">
          <mat-radio-group formControlName="trial_period">
            <mat-radio-button *ngFor="let item of master[Constant.joTrialPeriodType]" color="primary" [value]="item.id">
              {{ item.item_value }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="form.value.trial_period != Constant.trialPeriodTypeNone">
          <div class="label-count radio-bottom" *ngIf="trial_period_detail" [class.length-red]="trial_period_detail.value.length>maxLen100">
            {{ trial_period_detail.value.length }}/{{ maxLen100 }}
          </div>
          <textarea
            class="input-textarea contract"
            formControlName="trial_period_detail"
            placeholder="期間、条件・待遇の変更内容など"
            #trial_period_detail
          ></textarea>
          <mat-error *ngIf="!trial_period_detail.value" class="error">詳細を入力してください。</mat-error>
        </div>
      </div>
      <div class="employtype-area">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          給与 <span class="required">※金額は半角数字で下限必須</span>
        </div>
        <div class="value-area">
          <mat-form-field floatLabel="never" class="select-value">
            <mat-select formControlName="salary_type">
              <mat-option *ngFor="let item of master[Constant.joSalaryType]" [value]="item.id">
                {{ item.item_value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="form.value.salary_type == Constant.salaryTypeTime || form.value.salary_type == Constant.salaryTypeDay || form.value.salary_type == Constant.salaryTypeMonth
          || form.value.salary_type == Constant.salaryTypeNensyu || form.value.salary_type == Constant.salaryTypeNenpou" class="salary-input-box">
            <input
              class="jobplace-city salary-input"
              formControlName="salary_lower"
              placeholder="下限"
              type="text"
              [maxlength]="maxLen15"
              (input)="onInputNumber($event)"
              (compositionend)="onCompositionEnd($event)"
            />
            <span class="radio-text">円　〜</span>
            <input
              class="jobplace-city salary-input max-salary-left"
              formControlName="salary_higher"
              placeholder="上限"
              type="text"
              [maxlength]="maxLen15"
              (input)="onInputNumber($event)"
              (compositionend)="onCompositionEnd($event)"
            />
            <span class="radio-text">円</span>
            <mat-error *ngIf="!checkSararyInput()" class="error">金額は半角数字で入力してください。</mat-error>
          </div>
        </div>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          固定残業 <span class="required">※必須 </span>
        </div>
        <div class="indent-radio">
          <mat-radio-group formControlName="fixed_overtime">
            <mat-radio-button *ngFor="let item of master[Constant.joFixedOvertimeType]" color="primary" [value]="item.id">
              {{ item.item_value }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="form.value.fixed_overtime != Constant.fixedOvertimeNone">
          <div class="label-count radio-bottom" *ngIf="fixed_overtime_detail" [class.length-red]="fixed_overtime_detail.value.length>maxLen100">
            {{ fixed_overtime_detail.value.length }}/{{ maxLen100 }}
          </div>
          <textarea
            class="input-textarea contract"
            formControlName="fixed_overtime_detail"
            placeholder="（例）20時間分の固定残業代 : 78,000円を含む。超過分は追加で支給。"
            #fixed_overtime_detail
          ></textarea>
          <mat-error *ngIf="!fixed_overtime_detail.value" class="error">詳細を入力してください。</mat-error>
        </div>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          昇給・賞与
        </div>
        <div id="bonusdescription" class="label-count" [class.length-red]="bonusdescription.value.length>maxLen100">
          {{ bonusdescription.value.length }}/{{ maxLen100 }}
        </div>
        <textarea
          class="input-textarea contract"
          formControlName="raise"
          #bonusdescription
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          給与 備考
        </div>
        <div class="label-count" [class.length-red]="salary_note.value.length>maxLen200">
          {{ salary_note.value.length }}/{{ maxLen200 }}
        </div>
        <textarea
          class="input-textarea contract"
          formControlName="salary_note"
          #salary_note
        ></textarea>
      </div>
      <div class="job-placearea">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          勤務地 <span class="required">※必須 </span>
          <span class="normal">※求人を外部サービスに登録させたい場合は、勤務地を追加せず、1求人1勤務地で作成してください。</span>
        </div>
        <div class="jobplace-valuearea select-jobplace">
          <div class="jobplace-select-area select-button">
            <mat-form-field floatLabel="never" class="select-value-job">
              <mat-select
                formControlName="job_place"
                placeholder="都道府県"
              >
                <mat-option *ngFor="let jobplace of jobPlaceMaster" [value]="jobplace.id">
                  {{ jobplace.item_value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <input
              class="jobplace-city"
              formControlName="job_place_city"
              #jobplacecity
              placeholder="市区町村以下 ビル名なども記載"
              type="text"
              [maxlength]="maxLenAddress"
            />
          </div>
        </div>
        <div class="sub-jobplace-area">
          <div class="add-sub-job-text">
            <a class="add-sub-jobplace" (click)="onAddSubJobPlace()">
              勤務地を追加する
            </a>
          </div>

          <div *ngFor="let place of selectedSubJobPlaceAry" class="jobplace-valuearea">
            <div>
              <span class="pref">{{ place.item_value }}</span>
            </div>
            <div>
              <input
              class="jobplace-city"
              placeholder="市区町村以下 ビル名なども記載"
              type="text"
              [id]="'subplace-city-' + place.id"
              [value]="place.city"
              maxlength="255" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          勤務地 備考
        </div>
        <div id="salarydescription" class="label-count" [class.length-red]="job_place_note.value.length>maxLen200">
          {{ job_place_note.value.length }}/{{ maxLen200 }}
        </div>
        <textarea
          class="input-textarea contract"
          formControlName="job_place_note"
          #job_place_note
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          勤務時間 <span class="normal">※休憩時間も記載してください。</span>
        </div>
        <div class="label-count" [class.length-red]="work_hours.value.length>maxLen200">
          {{ work_hours.value.length }}/{{ maxLen200 }}
        </div>
        <textarea
          class="input-textarea contract"
          formControlName="work_hours"
          placeholder="勤務時間、休憩時間、平均残業時間など"
          #work_hours
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          休日・休暇
        </div>
        <div id="holidaydescription" class="label-count" [class.length-red]="holiday.value.length>maxLen400">
          {{ holiday.value.length }}/{{ maxLen400 }}
        </div>
        <textarea
          class="input-textarea skill"
          formControlName="holiday"
          #holiday
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          待遇・福利厚生
        </div>
        <div class="label-count" [class.length-red]="welfare.value.length>maxLen400">
          {{ welfare.value.length }}/{{ maxLen400 }}
        </div>
        <textarea
          class="input-textarea skill"
          formControlName="welfare"
          #welfare
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />  フリー項目（1）
          <div class="feee-title">
            <div id="salarydescription" class="label-count free" [class.length-red]="other_title1.value.length>maxLen20">
              {{ other_title1.value.length }}/{{ maxLen20 }}
            </div>
            <div>
              <input
                class="free-input-title"
                formControlName="other_title1"
                #other_title1
                placeholder="項目タイトル"
                type="text"
                [maxlength]="maxLen20"
              />
            </div>
          </div>
        </div>
        <div class="label-count" [class.length-red]="other_detail1.value.length>maxLen400">
          {{ other_detail1.value.length }}/{{ maxLen400 }}
        </div>
        <textarea
          class="input-textarea skill"
          formControlName="other_detail1"
          #other_detail1
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />  フリー項目（2）
          <div class="feee-title">
            <div class="label-count free" [class.length-red]="other_title2.value.length>maxLen20">
              {{ other_title2.value.length }}/{{ maxLen20 }}
            </div>
            <div>
              <input
                class="free-input-title"
                formControlName="other_title2"
                #other_title2
                placeholder="項目タイトル"
                type="text"
                [maxlength]="maxLen20"
              />
            </div>
          </div>
        </div>
        <div class="label-count" [class.length-red]="other_detail2.value.length>maxLen400">
          {{ other_detail2.value.length }}/{{ maxLen400 }}
        </div>
        <textarea
          class="input-textarea skill"
          formControlName="other_detail2"
          #other_detail2
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />  フリー項目（3）
          <div class="feee-title">
            <div class="label-count free" [class.length-red]="other_title3.value.length>maxLen20">
              {{ other_title3.value.length }}/{{ maxLen20 }}
            </div>
            <div>
              <input
                class="free-input-title"
                formControlName="other_title3"
                #other_title3
                placeholder="項目タイトル"
                type="text"
                [maxlength]="maxLen20"
              />
            </div>
          </div>
        </div>
        <div class="label-count" [class.length-red]="other_detail3.value.length>maxLen400">
          {{ other_detail3.value.length }}/{{ maxLen400 }}
        </div>
        <textarea
          class="input-textarea skill"
          formControlName="other_detail3"
          #other_detail3
        ></textarea>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          クロージング <span class="normal">※画像にリンク設定はできません。</span>
        </div>
        <app-quill-common *ngIf="editorData" [textData]="editorData[2]" (closeDialog)="onCloseDialog()" #quillClose></app-quill-common>
      </div>
      <div>
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          一覧ページ表示設定
        </div>
        <div class="label-desc">「表示しない」とした場合、コンテンツ一覧ページなどで本コンテンツが表示されません。<br>ただし、本コンテンツのURLをメッセージで送るなどして、ページに直接アクセスした場合は、誰でもコンテンツを閲覧できます。
        </div>
        <div class="indent-radio">
          <mat-radio-group formControlName="limited_flag">
            <mat-radio-button color="primary" [value]="0">
              表示する
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="1">
              表示しない
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onCancel()">キャンセル</button>
  <button
    mat-raised-button
    (click)="onSave()"
    [disabled]="checkSaveBtn()"
    class="yes-btn"
    *ngIf="mode == Constant.joTypeAdd"
  >
    作成
  </button>
  <button
    mat-raised-button
    (click)="onSave()"
    [disabled]="checkSaveBtn()"
    class="yes-btn"
    *ngIf="mode == Constant.joTypeEdit"
  >
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
