import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Auth } from 'aws-amplify';
import { AuthService } from './../../auth/auth.service';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { Constant } from './../../constant';
import { GeneralMessageDialogComponent } from '../general-message-dialog/general-message-dialog.component';

@Component({
  selector: 'app-message-edit-dialog',
  templateUrl: './message-edit-dialog.component.html',
  styleUrls: ['./message-edit-dialog.component.css']
})
export class MessageEditDialogComponent implements OnInit {
  public readonly Constant = Constant;
  public placeholder = '';
  public form: FormGroup;
  public maxLenTitle = 15;
  public maxLenTitleCsv = 30;
  public isSaving = false;

  constructor(
    public dialogRef: MatDialogRef<MessageEditDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onOK() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const data = {
      type: this.data.type,
      text: this.commonUtil.replaceSpace(this.form.value.text)
    };
    if (this.data.type === Constant.multiMsgEditTypeMemo || this.data.type === Constant.multiMsgEditTypeCsv) {
      data['title'] = this.commonUtil.replaceSpace(this.form.value.title);
    }
    this.updateMultiMessage(data);
  }

  private initForm() {
    let textControl;
    if (this.data.type === Constant.multiMsgEditTypeMessage) {
      textControl = new FormControl('', [Validators.required, Validators.minLength(100),  Validators.maxLength(Constant.vlMaxSendMessage)]);
      this.placeholder = 'メッセージを入力してください。';
      this.form = new FormGroup({
        text: textControl
      });
    } else if(this.data.type === Constant.multiMsgEditTypeCsv) {
      textControl = new FormControl('', [Validators.required, Validators.minLength(100),  Validators.maxLength(Constant.vlMaxSendMessage)]);
      this.placeholder = '本文を入力してください。';
      const titleControl = new FormControl('', [Validators.required, Validators.maxLength(this.maxLenTitleCsv)]);
      this.form = new FormGroup({
        text: textControl,
        title: titleControl
      });
    } else {
      textControl = new FormControl('', [Validators.maxLength(Constant.vlMaxSendMessage)]);
      this.placeholder = 'メモ';
      const titleControl = new FormControl('', [Validators.maxLength(this.maxLenTitle)]);
      this.form = new FormGroup({
        text: textControl,
        title: titleControl
      });
    }

    // 初期値設定
    setTimeout(() => {
      if (this.data.type === Constant.multiMsgEditTypeMessage) {
        this.form.setValue({
          text: this.data.text
        });
      } else if (this.data.type === Constant.multiMsgEditTypeCsv) {
        this.form.setValue({
          text: this.data.text,
          title: this.data.title
        });
      } else {
        this.form.setValue({
          text: this.data.text,
          title: this.data.title
        });
      }
    });
  }

  onMessageInfo() {
    this.dialog.open(GeneralMessageDialogComponent, {
      width: Constant.msgUrlInfoDialogWidth,
      autoFocus: false,
      data: Constant.carteMsgInfoAboutLink
    });
  }

  // 登録実行
  private updateMultiMessage(data) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.isSaving = true;
    const id = this.data.id;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      if (data.type === Constant.multiMsgEditTypeMessage) {
        options['body'] = {id: id, message: data.text};
      } else if (data.type === Constant.multiMsgEditTypeCsv) {
        options['body'] = {id: id, mail_title: data.title, message: data.text};
      } else if (data.type === Constant.multiMsgEditTypeMemo) {
        options['body'] = {id: id, memo: data.text, memo_title: data.title};
      }
      this.commonUtil
        .apiPut('/multimessage', options)
        .then(res => {
          this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
          this.dialogRef.close(true);
        })
        .catch(err => {
          if (err.status === Constant.NG) {
            this.toastUtil.showErrorToast('', Constant.msgResultSaveFailed, Constant.toastShowMiliSec);
            this.dialogRef.close(true);
          } else {
            this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
            this.isSaving = false;
          }
        });
    });
  }

}
