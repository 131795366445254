<div class="flex">
  <div class="side">
    <div class="button-container">
      <button mat-raised-button (click)="onSend()" [disabled]="totalCnt==0 || showSpinner || !talents || isSending" class="yes-btn">送信</button>
      <button mat-raised-button (click)="onCancel()" [disabled]="isSending">キャンセル</button>
    </div>
  </div>
  <div>
    <h2 mat-subheader class="title">一斉メッセージ送信内容の最終確認</h2>
    <div class="container">
      <div class="message">
        <div class="send-list margin-bottom">
          <img src="assets/notice/mark_gray.png"
          srcset="assets/notice/mark_gray.png    1x,
                  assets/notice/mark_gray@2x.png 2x,
                  assets/notice/mark_gray@3x.svg 3x " />
          <span class="font-bold"> 送信するメッセージ</span>
        </div>
        <div *ngIf="showSpinnerMsg" class="spinner">
          <mat-spinner></mat-spinner>
        </div>
        <ng-container *ngIf="!showSpinnerMsg">
        <div class="flex" *ngIf="message.mail_title">
          <div class="message-label">タイトル</div>
          <div class="text title">{{ message.mail_title }}</div>
        </div>
        <div class="flex">
          <div class="message-label">本文</div>
          <div class="text" [innerHtml]="message.message"></div>
        </div>
        </ng-container>
      </div>
      <!-- タレントリストを表示 -->
      <div class="send-list">
        <img src="assets/notice/mark_gray.png"
        srcset="assets/notice/mark_gray.png    1x,
                assets/notice/mark_gray@2x.png 2x,
                assets/notice/mark_gray@3x.svg 3x " />
        <span class="font-bold"> 送信予定の人数　<ng-container *ngIf="talents">{{totalCnt}}人</ng-container></span>
      </div>
      <div class="desc">
        現時点で一斉メッセージを送信できるタレントを表示・カウントします。<br>
        送信対象を選択・保存した後にタレントプールの登録を解消したタレントや、除外リストに追加されたタレント、配信停止となったタレントは表示・カウントされません。
      </div>
      <div *ngIf="(showSpinner && !talents) || isSending" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <!-- タレントリストを表示 -->
      <ng-container *ngIf="!isSending">
        <app-talent-list-common [talentData]="talentData"></app-talent-list-common>
        <div class="spinner" *ngIf="talents && showSpinner">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
      </ng-container>
    </div>
  </div>
</div>
