// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'staging',
  version: '1.0.0',
  amplify: {
    Auth: {
      identityPoolId: 'ap-northeast-1:cecfe30b-fbd9-4b18-938f-392479906092',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_jS3cypcg5',
      userPoolWebClientId: '2em489jkaecl3dguqiprhr408d'
    },
    API: {
      endpoints: [
        {
          name: 'company',
          endpoint: 'https://7ewi15ale6.execute-api.ap-northeast-1.amazonaws.com/stg'
        },
        {
          name: 'corp',
          endpoint: 'https://jmoore2ge7.execute-api.ap-northeast-1.amazonaws.com/dev'
        },
        {
          name: 'admin',
          endpoint: 'https://1z64k3um7j.execute-api.ap-northeast-1.amazonaws.com/prod'
        }
      ]
    },
    Storage: {
      offerCoverimage: {
        bucket: 'stg.talentpool.company.coverimage',
        region: 'ap-northeast-1',
        level: 'private'
      },
      companyLogo: {
        bucket: 'stg.talentpool.company.logo',
        region: 'ap-northeast-1',
        level: 'private'
      },
      companyPrImage: {
        bucket: 'stg.talentpool.company.primage',
        region: 'ap-northeast-1',
        level: 'private'
      },
      companyAboutMainImage: {
        bucket: 'stg.talentpool.company.about.main',
        region: 'ap-northeast-1',
        level: 'private'
      },
      companyDocument: {
        bucket: 'stg.talentpool.company.document',
        region: 'ap-northeast-1',
        level: 'private'
      },
      contentsImage: {
        bucket: 'stg.talentpool.company.contents',
        region: 'ap-northeast-1',
        level: 'private'
      },
      contentsCategoryImage: {
        bucket: 'stg.talentpool.company.contentscategory',
        region: 'ap-northeast-1',
        level: 'private'
      },
      contentsEditorImage: {
        bucket: 'stg.talentpool.company.contents.editor',
        region: 'ap-northeast-1',
        level: 'private'
      },
      jobofferImage: {
        bucket: 'stg.talentpool.company.jobofferimage',
        region: 'ap-northeast-1',
        level: 'private'
      },
      messageFile: {
        bucket: 'stg.talentpool.common.messagefile',
        region: 'ap-northeast-1',
        level: 'private'
      },
      talentcsv: {
        bucket: 'stg.talentpool.company.talentcsv',
        region: 'ap-northeast-1',
        level: 'private'
      },
    }
  },
  API_KEY: '0BUXtkI2K9aQtG7FG1CMZ5c0cBJkRslX6wGdZnl8',
  AnalyticsTrackingId: 'G-ZPKF6XX7D9',
  API_KEY_CORP: 'o5N89hWvX19w2HEdBJHYS4oWAVKtUZp21LMJnXIF',
  CORP_URL: 'https://d3mod1tgsfs16c.cloudfront.net',
  API_KEY_ADMIN: '8Cdkh2Ce1h7PSiZv0gSP92oj05fb9kAv3QMO9uLq',
  COLUMN_IMG_URL: 'https://company.calin.co.jp/assets/column/',
};
