import { TalentListCondition } from './../model/talent-list-condition';
import { Component, ElementRef, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { MatDialog } from '@angular/material/dialog';
import { TalentListPagingCondition } from '../model/TalentListPagingCondition';
import { GeneralYesNoDialogComponent } from '../dialog/general-yes-no-dialog/general-yes-no-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-multi-message-send',
  templateUrl: './multi-message-send.component.html',
  styleUrls: ['./multi-message-send.component.css']
})
export class MultiMessageSendComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public talents = null;
  public talentData;
  public totalCnt;
  public showSpinner = true;
  public showSpinnerMsg = true;
  public isSending = false;
  public message;
  private messageId;
  private apiPath = '/multimessage/send/';
  private scrollElement;
  private timer;  // スクロールイベント管理用タイマー
  private isSearching = false;
  private isNextData = true;
  private currentOffset = 0;
  private condition;

  constructor(
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private router: Router,
    private elementRef: ElementRef,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    // メッセージ詳細情報
    this.message = JSON.parse(localStorage.getItem(Constant.lsMultiMessageDetail));
    if (!this.message) {
      // メッセージ情報なしの場合、一覧画面に戻す
      this.router.navigate(['/message']);
      return;
    }
    this.messageId = this.message.id;

    // メッセージ最新情報取得
    this.getMessageDetail();

    // 検索条件設定
    const condition1: TalentListCondition = {
      type: Constant.tlTalentMultiMsgType,
      id: this.messageId
    };
    const condition2: TalentListCondition = {
      type: Constant.tlMultiMsgExceptType,
      id: 0
    };
    const condition3 = {
      type: Constant.tlDataStatusType,
      id: Constant.tlDataStatusTypeIdUnsubscribe
    };
    this.condition = [condition1, condition2, condition3];

    // タレントリスト取得
    this.getList();

    // タレントリスト表示用
    this.talentData = {
      talents: this.talents,
      checkFlg: false,
      listFlg: false,
    };

    // スクロールコンテナの設定
    this.scrollElement = this.elementRef.nativeElement.querySelector('.container');
  }

  ngOnDestroy() {
    localStorage.removeItem(Constant.lsMultiMessageDetail);
  }

  @HostListener('window:focus', ['$event'])
  onFocus() {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (!this.isNextData) {
      return;
    }

    // スクロール最下部で次データ取得
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (window.pageYOffset + window.innerHeight - 180 >= this.scrollElement.clientHeight) {
        this.getNextData();
      }
    }, 100);
  }

  // キャンセル・閉じる
  onCancel() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate(['/message']);
  }

  // 送信
  onSend() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.multiMsgConfirmSend }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.sendExec();
      }
    });
  }

  /* API */
  // タレント情報取得
  private getList() {
    if (!this.commonUtil.checkOnline()) {
      this.showSpinner = false;
      return;
    }

    if (this.isSearching) {
      return;
    }

    // ページング
    const pagingReqParam: TalentListPagingCondition = {
      offset: this.currentOffset,
      limit: Constant.tlItemPerPage
    };

    this.isSearching = true;
    Auth.currentSession().then(session => {
      const apiPath = '/talentlist';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        Conditions: this.condition,
        paging: pagingReqParam
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.commonUtil.debug().log(res.data);
          this.getListExec(res, options);
          this.isSearching = false;
        })
        .catch(err => {
          this.showSpinner = false;
          this.isSearching = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  // メッセージ詳細情報取得
  private getMessageDetail() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinnerMsg = false;
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = '/multimessage/detail/' + this.messageId;
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(apiPath, options).then(res => {
        this.message.message = res.data.msg.message;
        this.message.mail_title = res.data.msg.mail_title;
        this.convertMessageLink()
        this.showSpinnerMsg = false;
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinnerMsg = false;
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });

  }

  // 送信
  private sendExec() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.isSending = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {id: this.messageId, csv_flag: this.message.talent_csv_flag};

      this.commonUtil.apiPost(this.apiPath, options).then(res => {
        this.toastUtil.showInformationToast('', Constant.multiMsgSendSuccess, Constant.toastShowMiliSec);
        this.router.navigate(['/message']);
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.isSending = false;
        if (err.status === Constant.NG) {
          // 送信済みor削除済みの場合、一覧に戻る
          this.toastUtil.showErrorToast('', Constant.multiMsgSendFailed, Constant.toastShowMiliSec);
          this.router.navigate(['/message']);
        } else {
          this.toastUtil.showErrorToast('', Constant.multiMsgSendFailedRetry, Constant.toastShowMiliSec);
        }
      });
    });

  }

  /* その他プラベート関数 */
  // リストAPI取得後の処理
  private getListExec(data, options) {
    if (!this.talents) {
      this.talents = data.data.list;
    } else {
      this.talents.push(...data.data.list);
    }
    this.talentData.talents = this.talents;
    this.showSpinner = false;
    this.totalCnt = data.data.paging.total;
    // 次データ有無の設定
    if (data.data.list.length < Constant.tlItemPerPage) {
      this.isNextData = false;
    }

    if (data.data.list) {
      // 各タレントの画像URLを取得
      this.commonUtil.getTalentImg(data.data.list, options);
    }
  }

  // スクロールで次のデータ取得
  private getNextData() {
    if (this.showSpinner || !this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    this.currentOffset += Constant.tlItemPerPage;
    this.getList();
  }

  private convertMessageLink() {
    var regexp_makeLink = function(url) {
      return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    }

    const urlAllMatches = this.message.message.match(Constant.msgUrlRegExp);
    if (urlAllMatches !== null){
      let index = 0;

      let newMessage = ''
      let oldMessage = this.message.message
      urlAllMatches.forEach(url => {
        index++
        const tmp = oldMessage.replace(url, regexp_makeLink(url))

        // </a>で分割
        const newMessageAry = tmp.split('</a>', 2)
        newMessage += newMessageAry[0] + '</a>'
        if (newMessageAry.length>1) {
          oldMessage = newMessageAry[1]
        } else {
          oldMessage = ''
        }
      });
      newMessage += oldMessage
      this.message.message = this.domSanitizer.bypassSecurityTrustHtml(newMessage)
    }
  }

}
